import { Controller } from '@hotwired/stimulus';

export default class LabelsController extends Controller {
  static targets = ['selector'];

  static classes = ['collapsed'];

  activate() {
    this.selectorTarget.classList.remove(this.collapsedClass);
    document.addEventListener('click', this.#handleClickOutside.bind(this));
  }

  #deactivate() {
    this.selectorTarget.classList.add(this.collapsedClass);
    document.removeEventListener('click', this.#handleClickOutside.bind(this));
  }

  #handleClickOutside({ target }) {
    if (!this.element.contains(target)) {
      this.#deactivate();
    }
  }
}
