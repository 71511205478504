module.exports = {
  content: [
    'app/**/*.html.*',
    'app/components/**/*.rb',
    'app/helpers/*.rb',
    'app/models/label/colors.rb',
    'app/models/typography.rb',
  ],
  theme: {
    boxShadow: {
      branding: '-16px 12px rgba(0, 0, 0, .2)',
    },
    fontFamily: {
      archivo: ['Archivo', 'sans-serif'],
      sans: ['Plus Jakarta Sans', 'sans-serif'],
    },
    colors: {
      primary: '#4D67EA', // jetpack-workflow-primary-blue
      'primary-darkened': '#1C3BD6', // Custom, derived from jetpack-workflow-blue
      'primary-light': '#D1D9FF', // Custom, derived from jetpack-workflow-lightest-blue
      state: {
        // To be used in situations where color has meaning.
        // Darkened variations to be used for small non-bold text
        warning: '#EB7207', // Custom, derived from jetpack-workflow-yellow
        inprogress: '#EB7207', // Custom, derived from jetpack-workflow-yellow
        'success-light': '#F4FFFC',
        success: '#008262', // jetpack-workflow-green
        'success-darkened': '#027055', // Custom, derived from jetpack-workflow-green
        danger: '#F04747', // Custom, derived from jetpack-workflow-coral
        'danger-darkened': '#D60909', // Custom, derived from jetpack-workflow-coral
        next: '#714BB2', // jetpack-workflow-purple
        beta: '#EB7207',
      },
      grayscale: {
        white: '#FFFFFF', // White
        50: '#F5F7FA', // Custom, derived from jetpack-workflow-light-gray
        100: '#E9EDF5', // Custom, derived from jetpack-workflow-light-gray
        200: '#D1D9E5', // Custom, derived from jetpack-workflow-light-gray
        300: '#A3B2C9', // jetpack-workflow-light-gray
        500: '#6D7787', // jetpack-workflow-gray
        600: '#5D6675',
        700: '#444A54', // jetpack-workflow-dark-gray
        800: '#393C40',
        black: '#23262B', // jetpack-workflow-black
      },
      nav: {
        900: '#1C3BD6', // Custom, derived from jetpack-workflow-blue
        700: '#4D67EA', // jetpack-workflow-blue
        100: '#F6F6F6', // jetpack-workflow-super-light-gray
      },
      labels: {
        amber: {
          50: '#FFFBEB',
          600: '#D97706',
        },
        blue: {
          50: '#EFF6FF',
          100: '#DBEAFE',
          600: '#2563EB',
          900: '#1E3A8A',
        },
        emerald: {
          50: '#ECFDF5',
          900: '#064E3B',
        },
        fuchsia: {
          50: '#FDF4FF',
          100: '#FAE8FF',
          600: '#C026D3',
          900: '#701A75',
        },
        gray: {
          200: '#E5E7EB',
          700: '#374151',
        },
        green: {
          100: '#DCFCE7',
          600: '#16A34A',
        },
        indigo: {
          50: '#EEF2FF',
          100: '#E0E7FF',
          600: '#4F46E5',
          900: '#312E81',
        },
        lime: {
          50: '#F7FEE7',
          100: '#ECFCCB',
          600: '#65A30D',
          800: '#3F6212',
        },
        neutral: {
          100: '#F5F5F5',
          500: '#737373',
        },
        orange: {
          50: '#FFF7ED',
          100: '#FFEDD5',
          600: '#EA580C',
          800: '#9A3412',
        },
        pink: {
          50: '#FDF2F8',
          100: '#FCE7F3',
          600: '#DB2777',
          700: '#BE185D',
        },
        purple: {
          100: '#F3E8FF',
          700: '#7E22CE',
          900: '#581C87',
        },
        red: {
          100: '#FEE2E2',
          600: '#DC2626',
        },
        rose: {
          50: '#FFF1F2',
          900: '#881337',
        },
        sky: {
          100: '#E0F2FE',
          600: '#0284C7',
          900: '#0C4A6E',
        },
        teal: {
          100: '#CCFBF1',
          600: '#0D9488',
        },
      },
      transparent: 'transparent',
    },
    extend: {
      zIndex: {
        1: 1,
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms'), /* eslint-disable-line global-require */
    require('tailwind-scrollbar-hide'), /* eslint-disable-line global-require */
  ],
};
